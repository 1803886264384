import React, { useState, useEffect } from 'react';
import data from './data.json';
import mariusGif from './marius.gif';
import './darkMode.css';
import mariusFinish from './mariusFinish.png';
import pickle from './pickle.png';

function App() {
  const [displayText, setDisplayText] = useState('');
  const [showGif, setShowGif] = useState(false);
  const [theme, setTheme] = useState('dark');
  const [currentTextIndex, setCurrentTextIndex] = useState(-1);
  const [used, setUsed] = useState(false);
  const [showRandomImage, setShowRandomImage] = useState(false);
  const [randomTop, setRandomTop] = useState(0);
  const [randomLeft, setRandomLeft] = useState(0);
  const [randomWidth, setRandomWidth] = useState(0);
  

const handleRandomImageClick = () => {
  setShowRandomImage(true);
  calculateRandomWidth();
  calculateRandomPosition();
};

  const calculateRandomWidth = () => {

    const width = 100;
    const randomWidth = Math.floor(Math.random() *  10 * 100);
    setRandomWidth(randomWidth);
  }


  const calculateRandomPosition = () => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const imageWidth = randomWidth; // Adjust this value based on the actual image width
    const imageHeight = randomWidth; // Adjust this value based on the actual image height
  
    const maxTop = windowHeight - imageHeight;
    const maxLeft = windowWidth - imageWidth;
  
    const newTop = Math.floor(Math.random() * maxTop);
    const newLeft = Math.floor(Math.random() * maxLeft);
  
    setRandomTop(newTop);
    setRandomLeft(newLeft);

    const handleResize = () => {
      calculateRandomPosition();
    };

    
  window.addEventListener('resize', handleResize);

  return () => {
    window.removeEventListener('resize', handleResize);
  };
  };

  useEffect(() => {
    calculateRandomPosition();
  }, []);
  

  const toggleTheme = () => {
    if (theme === 'light') {
      setTheme('dark');
    } else {
      setTheme('light');
    }
  };

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  const handleClick = () => {
    if(!used){
      setUsed(true);
    }
    if (displayText) {
      setDisplayText('');
      setCurrentTextIndex(-1);
    } 
      
      setShowGif(true);
  
      const totalDuration = 1700; // Total duration in milliseconds
      const delayPerItem = totalDuration / 10; // Duration for each of the 5 random items
      const rollDuration = delayPerItem * 10; // Duration for the roll animation
      const randomIndex = Math.floor(Math.random() * data.texts.length);
  
      let currentIndex = 0;
      
  
      const rollInterval = setInterval(() => {
        if (currentIndex < 10) {
          const randomTextIndex = Math.floor(Math.random() * data.texts.length);
          setDisplayText(data.texts[randomTextIndex]);
        } else if (currentIndex === 10) {
          setDisplayText(data.texts[randomIndex]);
        }
  
        currentIndex++;
        if (currentIndex >= 11) {
          clearInterval(rollInterval);
          setShowGif(false);
        }
      }, currentIndex < 10 ? delayPerItem : rollDuration);
  
      setCurrentTextIndex(currentIndex);
      
    
  };
  
  
  
  const textStyle = {
    fontSize: "35px",
    textAlign: "center",
  };


  return (
    <div className={`App ${theme}`} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <button onClick={toggleTheme}>{(theme === 'light')? '🌞' : '🌚'}</button>
      <h1>Fischersnet Ausreden Simulator.</h1>
      <p>Du bist ein up and coming Streamer, hast aber gerade keine Lust zu streamen und brauchst eine Ausrede? Kein Problem, klick den Button, kopier den Text und schicks deiner enttäuschten Community.</p>
      <button onClick={handleClick}>Gib Ausrede!</button>
        {displayText? <p>Deine Ausrede:</p> : <p />}
      <div className="textWrapper">
        {displayText && (<p
          className={currentTextIndex >= 0 ? 'roll-effect' : ''}
            style={textStyle}
          >
          {displayText}
        </p>
)}


      </div>
{showGif? <img src={mariusGif}  width="600px" alt="Loading" />: used? <img src={mariusFinish} width="600px" alt="Marius" /> : <div />}

  <div style={{ position: 'absolute', bottom: 0, right: 5 }}>
    <p>by Fabse</p>
  </div>

  
  {/* <div style={{ position: 'absolute', bottom: 0, left: 5 }}>
  <button onClick={handleRandomImageClick}>
  <img src={pickle} width="25px" alt="Random Image Button" />
</button>
  </div> */}
  {showRandomImage && (
  <img
    src={pickle}
    style={{
      position: 'absolute',
      top: `${randomTop}px`,
      left: `${randomLeft}px`,
      width: `${randomWidth}px`
    }}
    alt="Random Image"
  />
)}

  </div>
  );
}

export default App;
